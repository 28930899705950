import React, { useState, useRef } from 'react';
import { FiChevronRight, FiChevronLeft, FiTrash2 } from "react-icons/fi"
import axios from "axios";

import "./midt.css"

const Midt = (props) => {
  const [currentIndex, setCurrectIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [cursorXSpace, setCursorXSpace] = useState(0);

  const containerRef = useRef(null);
  const imagesRef = useRef(null);

  const handlePrevClick = (button) => {
    const imagesWidth = imagesRef.current.offsetWidth;
    if (button) {
        imagesRef.current.style.transition = "none";
        imagesRef.current.style.left = `-${(imagesWidth / 5 * 2) + (imagesWidth / 100 * 2 * 2)}px`;
        setCurrectIndex(currentIndex === 0 ? props.slideshow.length - 1 : currentIndex - 1);
        setTimeout(() => {
            imagesRef.current.style.left = `-${(imagesWidth / 5) + (imagesWidth / 100 * 2)}px`;
            imagesRef.current.style.transition = "0.5s";
        }, 1);
    } else {
        setCurrectIndex(currentIndex === 0 ? props.slideshow.length - 1 : currentIndex - 1);
    }
  };

  const handleNextClick = (button) => {
    const imagesWidth = imagesRef.current.offsetWidth;
        if (button) {
            imagesRef.current.style.transition = "none";
            imagesRef.current.style.left = `0px`;
            setCurrectIndex(currentIndex === props.slideshow.length - 1 ? 0 : currentIndex + 1);
            setTimeout(() => {
                imagesRef.current.style.left = `-${(imagesWidth / 5) + (imagesWidth / 100 * 2)}px`;
                imagesRef.current.style.transition = "0.5s";
            }, 1);
        } else {
            setCurrectIndex(currentIndex === props.slideshow.length - 1 ? 0 : currentIndex + 1);
        }
  };

  const handleDotClick = (index) => {
    setCurrectIndex(index);
  };

  const handleDragStart = (e) => {
    imagesRef.current.style.transition = "none";
    setDragging(true);
    setCursorXSpace(e.pageX ? e.pageX - imagesRef.current.offsetLeft : e.touches[0].clientX - imagesRef.current.offsetLeft)
  }

  const handleDragEnd = () => {
    setDragging(false);
    const imagesLeft = -parseInt(imagesRef.current.style.left);
    const imagesWidth = imagesRef.current.offsetWidth;

    if (imagesLeft < ((imagesWidth / 5) + (imagesWidth / 100 * 2)) * 0.5555555555555556) {
        handlePrevClick();
        const imagesLeft = -parseInt(imagesRef.current.style.left);
        imagesRef.current.style.left = `-${(imagesWidth / 5) + (imagesWidth / 100 * 2) + imagesLeft}px`;
        setTimeout(() => {
            imagesRef.current.style.transition = "0.5s";
            imagesRef.current.style.left = `-${(imagesWidth / 5) + (imagesWidth / 100 * 2)}px`;
        }, 50);

    } else if (imagesLeft > ((imagesWidth / 5) + (imagesWidth / 100 * 2)) * 1.4444444444444444) {
        handleNextClick();
        const imagesLeft = -parseInt(imagesRef.current.style.left);
        imagesRef.current.style.left = `-${imagesLeft - ((imagesWidth / 5) + (imagesWidth / 100 * 2))}px`;
        setTimeout(() => {
            imagesRef.current.style.transition = "0.5s";
            imagesRef.current.style.left = `-${(imagesWidth / 5) + (imagesWidth / 100 * 2)}px`;
        }, 50);
    } else {
        imagesRef.current.style.transition = "0.5s";
        imagesRef.current.style.left = `-${(imagesWidth / 5) + (imagesWidth / 100 * 2)}px`;
    }
    imagesRef.current.style.pointerEvents = "auto";
  }

  const handleDragMove = (e) => {
    if (!dragging) return;
    imagesRef.current.style.pointerEvents = "none";

    imagesRef.current.style.left = `${e.pageX ? e.pageX - cursorXSpace : e.touches[0].clientX - cursorXSpace}px`;
    BoundImages();
  }

  const BoundImages = () => {
    const container_rect = containerRef.current.getBoundingClientRect();
    const images_rect = imagesRef.current.getBoundingClientRect();
    
    if (parseInt(imagesRef.current.style.left) > 0) {
        imagesRef.current.style.left = 0;
    } else if ((images_rect.right + (images_rect.width / 100 * 2 * 4)) < container_rect.right) {
        imagesRef.current.style.left = `-${images_rect.width - container_rect.width + (images_rect.width / 100 * 2 * 4)}px`;
    }
  }

  const slet = async (img) => {
    const formData = new FormData();
    formData.append("file", img);

    await axios.post("/unlinkFile", formData, {
      headers: {
        'Contents-Type':'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin":"*",
      }
    })
    .then(
      window.location.reload()
    );
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(selectedFile);
  }

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    await axios.post("/appendFile", formData, {
      headers: {
        'Contents-Type':'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin":"*",
      }
    })
    .then(
      window.location.reload()
    );
  };

  return (
    <div className="Midt">
      <div className="container-antal">{`${currentIndex + 1} / ${props.slideshow.length}`}</div>

      {props.auth === true && 
        <div className="slideshow-knap">
          <button name="admin"
            id="tilfoj"
            className="btn btn-secondary"
            data-bs-toggle="modal"
            data-bs-target="#tilfoj_modal"
          >
            Tilføj Billede
          </button>

          <div className="modal fade"
            id="tilfoj_modal"
            tabIndex="-1"
            aria-labelledby="tilfoj_label"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="tilfoj_label">
                    Opmærksom!
                  </h1>
                  <button type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>Upload dit billede herunder:</p>
                  <form method="POST"
                    action="https://www.lisehoeg.dk"
                    target="_blank"
                    id="billede_upload"
                  >
                    <div className="form-group">
                      <input type="file"
                        className="form-control"
                        id="file"
                        name="file"
                        accept=".png, .jpg, .gif, .jpeg"
                        onChange={onFileChange}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button"
                    className="btn btn-success"
                    onClick={onFileUpload}
                    id="liveAlertBtn"
                  >
                    Tilføj
                  </button>
                  <button type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Annuller
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <div className="container-flex-row">
        <button onClick={handlePrevClick} className="btn"> <FiChevronLeft size={50} color={"rgb(87, 87, 87)"} /> </button>

        {props.slideshow && props.slideshow.length > 0 && 
          <div
              className="container-slideshow"
              onMouseDown={handleDragStart}
              onMouseMove={handleDragMove}
              onMouseUp={handleDragEnd}
              onMouseLeave={handleDragEnd}
              onTouchStart={handleDragStart}
              onTouchEnd={handleDragEnd}
              onTouchCancel={handleDragEnd}
              onTouchMove={handleDragMove}
              ref={containerRef}
          >
              <div className="container-slideshow-images" ref={imagesRef}>
                  <div className="billede">
                      <img
                          src={`./static/${props.slideshow[currentIndex < 2 ? props.slideshow.length - 2 + currentIndex : currentIndex - 2]}`} 
                          alt={props.slideshow[currentIndex < 2 ? props.slideshow.length - 2 + currentIndex : currentIndex - 2]} 
                      />
                  </div>
                  <div className="billede">
                      <img 
                          src={`./static/${props.slideshow[currentIndex === 0 ? props.slideshow.length - 1 : currentIndex - 1]}`} 
                          alt={props.slideshow[currentIndex === 0 ? props.slideshow.length - 1 : currentIndex - 1]} 
                      />
                  </div>
                  <div className="billede">
                      <img 
                          src={`./static/${props.slideshow[currentIndex]}`} 
                          alt={props.slideshow[currentIndex]} 
                      />
                  </div>
                  <div className="billede">
                      <img 
                          src={`./static/${props.slideshow[currentIndex === props.slideshow.length - 1 ? 0 : currentIndex + 1]}`} 
                          alt={props.slideshow[currentIndex === props.slideshow.length - 1 ? 0 : currentIndex + 1]} 
                      />
                  </div>
                  <div className="billede">
                      <img 
                          src={`./static/${props.slideshow[currentIndex > props.slideshow.length - 3 ? currentIndex - props.slideshow.length + 2 : currentIndex + 2]}`} 
                          alt={props.slideshow[currentIndex > props.slideshow.length - 3 ? currentIndex - props.slideshow.length + 2 : currentIndex + 2]}
                      />
                  </div>
              </div>
          </div>
        }

        {props.auth === true && 
          <button name="admin"
            id="slet"
            data-bs-toggle="modal"
            data-bs-target="#slet_modal">
            <FiTrash2 size={30} />
          </button>
        }

        <button onClick={handleNextClick} className="btn"> <FiChevronRight size={50} color={"rgb(87, 87, 87)"} /> </button>
      </div>

      {props.auth === true && 
        <div className="modal fade"
          id="slet_modal"
          tabIndex="-1"
          aria-labelledby="slet_label"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="slet_label">
                  Opmærksom!
                </h1>
                <button type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Er du sikker på at du gerne vil slette dette billede?<br></br>{" "}
                </p>
                {props.slideshow.length > 0 && 
                  <img src={`./static/${props.slideshow[currentIndex]}`}
                    className="img-fluid rounded mx-auto d-block"
                    alt={props.slideshow[currentIndex]}
                  />
                }
              </div>
              <div className="modal-footer">
                <button type="button"
                  className="btn btn-danger"
                  onClick={() => slet(props.slideshow[currentIndex])}
                >
                  Slet
                </button>
                <button type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Annuller
                </button>
              </div>
            </div>
          </div>
        </div>}

      <div className="container-dots">
        {props.slideshow.map((_, index) => (
          <span key={index} onClick={() => handleDotClick(index)} className={`dots ${index === currentIndex && "active"}`}></span>
        ))}
        </div>

    </div>
  )
}

export default Midt